import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import { FormattedMessage } from "react-intl";

const styles = theme => ({
  imageCarousel: {
    '&::before': {
      background: window.PROMO_BANNER_TINT_DISABLED ? 'none !important' : ''
    },
      '&::after': {
      background: window.PROMO_BANNER_TINT_DISABLED ? 'none !important' : ''
    }
  },
  text: {
    [theme.breakpoints.up(991)]: {
      color: window.PROMO_BANNER_FONT_COLOUR + ' !important'
    }
  },
  button: {
    [theme.breakpoints.down(992)]: {
      background: window.PROMO_BANNER_MOBILE_BUTTON_BACKGROUND_COLOUR,
      color: window.PROMO_BANNER_MOBILE_BUTTON_FONT_COLOUR
    },
    [theme.breakpoints.up(991)]: {
      background: window.PROMO_BANNER_BUTTON_BACKGROUND_COLOUR,
      color: window.PROMO_BANNER_BUTTON_FONT_COLOUR
    }
  }
});

export class PromoBanner extends React.Component {
  constructor(props) {
    super(props);

    this.handleTextCarouselChange = this.handleTextCarouselChange.bind(this);
    this.handleImageCarouselChange = this.handleImageCarouselChange.bind(this);
    this.imageCarouselRef = React.createRef();
    this.textCarouselRef = React.createRef();
    this.carouselUpdating = false;
  }

  componentWillUnmount() {
    this.textCarouselRef.current.stop();
  }

  handleTextCarouselChange(e) {
    if (e.property.name !== 'position') {
      return;
    }
    this.moveCarousel(this.imageCarouselRef, e.property.value > e.item.index);
  }

  handleImageCarouselChange(e) {
    if (e.property.name !== 'position') {
      return;
    }
    this.moveCarousel(this.textCarouselRef, e.property.value > e.item.index);
  }

  moveCarousel(carouselRef, forward) {
    if (!this.carouselUpdating) {
      this.carouselUpdating = true;
      this.textCarouselRef.current.stop();
      if (forward) {
        carouselRef.current.next(500);
      }
      else {
        carouselRef.current.prev(500);
      }
    }
    else {
      this.carouselUpdating = false;
      this.textCarouselRef.current.play();
    }
  }

  render() {
    const { banners, classes } = this.props;

    return (
      <section className="banner-slider-container">
        <article className="banner-slider medium-text">
          <OwlCarousel className={classNames(classes.imageCarousel, 'image-carousel')} style={{background: 'none'}} ref={this.imageCarouselRef} onChange={this.handleImageCarouselChange}
            dots={false} nav={false} items={1} loop autoplay={false} animateOut={'fadeOut'}
            mouseDrag={false} touchDrag={false} pullDrag={false}
            navText={["<i class='material-icons outlined'>arrow_back_ios</i>", "<i class='material-icons outlined'>arrow_forward_ios</i>"]}
            responsive={{
              0: {
                nav: true
              },
              991: {
                nav: false
              }
          }}>
            {banners.map((promo, index) =>
              <div key={index} className="image-slide">
                <img src={promo.image} alt={promo.title} />
              </div>
            )}
          </OwlCarousel>

          <OwlCarousel className='text-carousel' ref={this.textCarouselRef} onChange={this.handleTextCarouselChange}
            dots={false} nav items={1} loop autoplay={true} autoplayTimeout={4000} autoplayHoverPause={true} animateOut={'fadeOut'}
            mouseDrag={false} touchDrag={false} pullDrag={false}
            navText={["<i class='material-icons outlined'>arrow_back_ios</i>", "<i class='material-icons outlined'>arrow_forward_ios</i>"]}
            responsive={{
              0: {
                dots: false,
                nav: false
              },
              991: {
                dots: false,
                nav: true
              }
          }}>
            {banners.map((promo, index) =>
              <div key={index} className="text-slide">
                <div className="text-slide-inner">
                  <Typography variant="h2" className={classes.text}>{promo.title}</Typography>
                  {promo.description && <Typography variant="subtitle1" className={classes.text}>{renderHTML(promo.description)}</Typography>}
                  <NavLink to={promo.action_path} className='button-link'>
                    <Button variant='contained' color='primary' className={classes.button}>
                      {promo.button_text ?
                        promo.button_text
                      :
                        <FormattedMessage id="promo_banner.shop_now" defaultMessage="Shop Now"/>
                      }
                    </Button>
                  </NavLink>
                </div>
              </div>

            )}
          </OwlCarousel>
        </article>
      </section>
    )
  }
}

export default withStyles(styles)(PromoBanner);
